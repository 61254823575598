<script>
import moment from 'moment'
import { loadScript } from '@paypal/paypal-js'
import { captureMembershipOrder, checkOrderStatus, createMembershipOrder } from '@/api/data'

export default {
  name: 'MembershipUpgrade',
  data () {
    return {
      selected: this.record.user_level,
      num: 1,
      payShow: false,
      paySuccess: false,
      expireTime: moment().add(1, 'month').format('YYYY年MM月DD日'),
      timer: null
    }
  },
  props: ['record', 'layerid'],
  beforeDestroy () {
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
  },
  created () {
    if (this.record.level_expire && moment().isBefore(this.record.level_expire)) {
      this.expireTime = moment(this.record.level_expire).add(1, 'month').format('YYYY年MM月DD日')
    }
    const _this = this
    loadScript({
      // 'environment': 'sandbox',
      // 'client-id': 'Aea6Z6vCwKjwTXVaTKa2268YxNwo5Kja9lV_Tcm04zVeWYEXMrO316y5es7NnSwYvmhietyuQHMSP1bS',
      'client-id': 'AV2loXix91fcINCOXVhq9jnl289U1V8PH5ANYmVdnI4FmPZ7hEhoLUyX0blYxG5b7G59pM7E7JVpfpCk',
      'currency': 'JPY'
    })
      .then((paypal) => {
        paypal.Buttons({
            async createOrder () {
              try {
                const orderData = await createMembershipOrder({
                  num: _this.num,
                  type: _this.selected,
                  uid: _this.record.user_id
                })

                if (orderData.id) {
                  return orderData.id
                } else {
                  const errorDetail = orderData?.details?.[0]
                  const errorMessage = errorDetail
                    ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                    : JSON.stringify(orderData)

                  throw new Error(errorMessage)
                }
              } catch (error) {
                console.error(error)
                _this.resultMessage(`Could not initiate PayPal Checkout...`)
              }
            },
            async onApprove (data, actions) {
              if (_this.timer) {
                clearInterval(_this.timer)
                _this.timer = null
              }
              _this.timer = setInterval(() => {
                checkOrderStatus({ orderId: data.orderID }).then(res => {
                  if (res.code === 0 && res.data === 1) {
                    _this.paySuccess = true
                    clearInterval(_this.timer)
                    setTimeout(function () {
                      _this.$parent.refreshTable()
                      _this.$layer.close(_this.layerid)
                    }, 2000)
                  }
                })
              }, 2000)
              try {
                const orderData = await captureMembershipOrder({
                  orderId: data.orderID
                })
                // Three cases to handle:
                //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                //   (2) Other non-recoverable errors -> Show a failure message
                //   (3) Successful transaction -> Show confirmation or thank you message

                const errorDetail = orderData?.details?.[0]

                if (errorDetail?.issue === 'INSTRUMENT_DECLINED') {
                  // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                  // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
                  return actions.restart()
                } else if (errorDetail) {
                  // (2) Other non-recoverable errors -> Show a failure message
                  throw new Error(`${errorDetail.description} (${orderData.debug_id})`)
                } else if (!orderData.purchase_units) {
                  throw new Error(JSON.stringify(orderData))
                } else {
                  console.log(
                    'Capture result',
                    orderData,
                    JSON.stringify(orderData, null, 2)
                  )
                  if (orderData.status === 'COMPLETED') {
                    _this.paySuccess = true
                    clearInterval(_this.timer)
                    setTimeout(function () {
                      _this.$parent.refreshTable()
                      _this.$layer.close(_this.layerid)
                    }, 2000)
                  }
                }
              } catch (error) {
                console.error(error)
              }
            }
          }
        ).render('#paypal-button-container')
      })
      .catch((err) => {
        console.error('failed to load the PayPal JS SDK script', err)
      })
  },
  methods: {
    moment,
    select (index) {
      if (this.record.level_expire && moment().isBefore(this.record.level_expire) && this.record.user_level === 2) {
        this.$message.error('現在のPro+会員は期限切れになるまで降格をサポートしていません。')
        return
      }
      this.selected = index
    },
    handleChangeNum (e) {
      this.num = e
      if (this.record.level_expire && moment().isBefore(this.record.level_expire)) {
        this.expireTime = moment(this.record.level_expire).add(this.num, 'month').format('YYYY年MM月DD日')
      } else {
        this.expireTime = moment().add(this.num, 'month').format('YYYY年MM月DD日')
      }
    },
    resultMessage () {

    },
    onClose () {
      this.payShow = false
    },
    startPay () {
      this.payShow = true
    }
  }
}
</script>

<template>
  <div style="width: 100%;padding: 8px;">
    <div>
      <a-alert message="現在は試用会員。" banner v-if="record.user_level==0" />
      <a-alert
        :message="'現在はPro会員,有効日は'+moment(record.level_expire).format('YYYY年MM月DD日')+'。'"
        banner
        v-if="record.user_level==1" />
      <a-alert
        :message="'現在はPro+会員,有効日は'+moment(record.level_expire).format('YYYY年MM月DD日')+'。'"
        banner
        v-if="record.user_level==2" />
    </div>
    <div style="margin-top: 8px;">
      <a-row :gutter="8">
        <a-col :span="6">
          <div class="ant-card membership-card">
            <div class="membership-card-header" style="border-bottom: 1px solid #eee;">{{ $t('user.membership.function')
            }}
            </div>
            <div class="membership-card-body">
              <div class="fun-item">{{ $t('user.membership.function.data.limit') }}</div>
              <div class="fun-item">{{ $t('user.membership.function.excel') }}</div>
              <div class="fun-item">{{ $t('user.membership.function.location') }}</div>
              <div class="fun-item">ビデオ記録 3秒</div>
            </div>
          </div>
        </a-col>
        <a-col :span="6">
          <div
            class="ant-card membership-card card-grey cursor"
            :class="selected==0?'card-selected':''"
            @click="select(0)">
            <div class="membership-card-header">試用会員（無料）</div>
            <div class="membership-card-body">
              <div class="fun-item">3{{ $t('user.membership.days') }}</div>
              <div class="fun-item">×</div>
              <div class="fun-item">×</div>
              <div class="fun-item">×</div>
            </div>
          </div>
        </a-col>
        <a-col :span="6">
          <div
            class="ant-card membership-card card-copper cursor"
            :class="selected==1?'card-selected':''"
            @click="select(1)">
            <div class="membership-card-header">Pro会員（500円/月）</div>
            <div class="membership-card-body">
              <div class="fun-item">制限なし</div>
              <div class="fun-item">√</div>
              <div class="fun-item">×</div>
              <div class="fun-item">×</div>
            </div>
          </div>
        </a-col>
        <a-col :span="6">
          <div
            class="ant-card membership-card card-gold cursor"
            :class="selected==2?'card-selected':''"
            @click="select(2)">
            <div class="membership-card-header">Pro+会員（1000円/月）</div>
            <div class="membership-card-body">
              <div class="fun-item">制限なし</div>
              <div class="fun-item">√</div>
              <div class="fun-item">√</div>
              <div class="fun-item">√</div>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>

    <div style="height: 38px;margin-top: 12px;">
      <div
        v-if="selected>0">
        <span>開始月：</span>
        <a-input-number
          v-model="num"
          @change="handleChangeNum"
          :min="1"
          :max="12"
          style="width: 80px;"></a-input-number>
        <span style="margin-left: 8px;">月</span>
        <span style="margin-left: 20px;">有効期限は{{ expireTime }}までの予定（実際の支払済開通期間は基準）</span>
      </div>
    </div>

    <div style="margin-top: 10px;clear: both;">
      <span style="line-height: 36px;">合计：{{ selected === 0 ? 0 : selected * 500 * num }}円</span>
      <a-button :disabled="selected===0" :type="selected===0?'':'primary'" class="float-right" @click="startPay">
        {{ selected === 0 ? '支払い不要' : '支払オープン' }}
      </a-button>
    </div>
    <a-drawer
      title="支払いは会員に"
      placement="right"
      :width="300"
      :closable="true"
      :visible="payShow"
      :get-container="false"
      @close="onClose"
    >
      <div style="line-height: 40px;text-align: center;">支払い{{ selected * 500 * num }}円</div>
      <div v-if="paySuccess" style="text-align: center">
        <a-result
          status="success"
          title="支払いに成功しました"
        ></a-result>
        <div></div>
      </div>
      <div id="paypal-button-container" v-else></div>
    </a-drawer>
  </div>
</template>

<style scoped lang='less'>

.membership-card {
  border-radius: 8px;
  height: 198px;

  border: 1px solid #eaeaea;

  .membership-card-header {
    text-align: center;
    font-size: 1.0rem;
    line-height: 40px;
    font-weight: bold;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .membership-card-body {
    padding: 8px;
    text-align: center;
  }

  .membership-card-body .fun-item {
    border-bottom: 1px solid #eee;
    line-height: 36px;
  }

  .membership-card-body .fun-item:last-child {
    border-bottom: none;
  }
}

.card-grey {

  .membership-card-header {
    background-color: #eee;
    color: #333;
  }
}

.card-copper {

  .membership-card-header {
    background-color: #FF9900;
    color: #fff;
  }
}

.card-gold {

  .membership-card-header {
    background-color: #FFCC00;
    color: #fff;
  }
}

.card-selected {
  border: 2px solid #ff0000;
}

.cursor {
  cursor: pointer;
}
</style>
